<template>
  <div class="Student-WriteInfo">
    <div class="stu-module-header">
      <div class="stu-module-title">考试信息</div>
      <div class="stu-writeinfo-btn" v-if="activeName === 'two'">
        <el-button
          type="primary"
          @click="exemptionsAdd"
          v-if="$sysPerKey('eduOnlineQa:exemptionSubmit')"
          >添加</el-button
        >
        <el-button
          type="primary"
          @click="onDelete"
          v-if="$sysPerKey('eduOnlineQa:updateExemptionSubmit')"
          >删除</el-button
        >
      </div>
    </div>

    <el-tabs v-model="activeName" @tab-click="handleTabClick">
      <!-- <el-tab-pane name="one">
        <span slot="label"> 考试信息 </span>
        <write-info />
      </el-tab-pane> -->
      <el-tab-pane name="two">
        <span slot="label"> 免试申请 </span>
        <Exemptions
          ref="exemptionsRef"
          v-if="$sysPerKey('eduOnlineQa:queryExemptionSubmit')"
          @selectData="selectData"
        />
        <no-root v-else></no-root>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import WriteInfo from "./module/WriteInfo.vue";
import Exemptions from "./module/Exemptions.vue";
import { UpdateExemptionSubmit } from "@/libs/api/student";
export default {
  name: "my-course",
  components: {
    Exemptions,
    WriteInfo,
  },
  data() {
    return {
      activeName: "two",

      select: [],
    };
  },
  methods: {
    handleTabClick(tab) {},

    exemptionsAdd() {
      this.$refs["exemptionsRef"].again = false;
      this.$refs["exemptionsRef"].dialog = true;
    },
    selectData(list) {
      this.select = list;
    },
    onDelete() {
      if (!this.select.length) {
        return this.$message.error("请至少选择列表中一条数据");
      }
      UpdateExemptionSubmit({ ids: this.select.map((m) => m.id).join() }).then(
        (res) => {
          this.$message.success("删除成功");
          this.$refs["exemptionsRef"].init();
        }
      );
    },
  },
};
</script>

<style scoped lang="less">
.Student-WriteInfo {
  background: #fff;
  box-shadow: @shadow;
  .stu-module-title {
    padding: 20px;
  }
  .item {
    position: absolute;
    top: -10px;
    right: auto;
  }
  .stu-writeinfo-btn {
    padding-right: 20px;
  }
}
</style>