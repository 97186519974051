<template>
  <div class="Exemptions">
    <el-table
      v-loading="loading"
      :data="datalist"
      :max-height="500"
      :height="500"
      ref="writeTableRef"
      stripe
      @selection-change="selectionChange"
    >
      <el-table-column type="selection" :width="60" align="center">
      </el-table-column>
      <el-table-column label="序号" :width="60" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in columns"
        :prop="item.prop"
        :key="index"
        :label="item.label"
        align="center"
        :min-width="item.width"
        :show-overflow-tooltip="item.tooltip"
      ></el-table-column>
      <el-table-column
        prop="attachmentUrl"
        label="附件"
        :min-width="130"
        align="center"
      >
        <template slot-scope="scope" v-if="scope.row.attachmentUrl">
          <el-button type="text" @click="OpenFile(scope.row.attachmentUrl)">
            查看附件
          </el-button>
          <el-button type="text" @click="againUpload(scope.row.id)">
            重新上传
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="tableData.length"
    >
    </el-pagination>

    <el-dialog
      :title="again ? '重新上传' : '新增免试申请'"
      width="40%"
      :visible.sync="dialog"
    >
      <el-form
        :model="form"
        label-width="120px"
        :rules="rules"
        ref="exemptionsFormRef"
      >
        <el-form-item label="选择免试课程" prop="selectCourse" v-if="!again">
          <el-select v-model="form.selectCourse" clearable>
            <el-option
              v-for="(item, index) in selectCourseOptions"
              :key="index"
              :label="item.courseName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传附件" prop="file">
          <el-upload
            class="upload-demo"
            :action="actionUrl"
            :data="actionData"
            :headers="{
              'x-token': auth['x-token'],
              'x-perm': auth['x-perm'],
            }"
            ref="upload"
            :on-change="onChange"
            :on-remove="handleRemove"
            :on-success="onSuccess"
            :file-list="form.file"
            :auto-upload="false"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <p style="color: red;font-size: 14px; display: inline-block; margin-left: 10px; font-weight: bold;">仅支持office文件格式</p>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="confirm('exemptionsFormRef')">
          确认
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { QueryExemptionSubmit, QueryExemptionCourse } from "@/libs/api/student";
import { BASE_URL } from "@/libs/domain";
export default {
  name: "student-exemptions",
  data() {
    return {
      loading: false,
      columns: [
        { prop: "courseName", label: "课程", width: 180, tooltip: true },
        { prop: "applyTime", label: "提交时间", width: 120, tooltip: true },
        { prop: "checkStatus", label: "审核状态" },
        { prop: "rejectReason", label: "审核意见", width: 120, tooltip: true },
      ],
      tableData: [],
      current: 1,
      pageSize: 10,
      total: 0,

      form: {
        selectCourse: null,
        file: [],
      },
      rules: {
        selectCourse: [
          { required: true, message: "请选择免试课程", trigger: "blur" },
        ],
        file: [{ required: true, message: "请选择附件", trigger: "blur" }],
      },
      dialog: false,
      selectCourseOptions: [],
      again: false,
      againId: null,
    };
  },
  computed: {
    datalist() {
      return this.tableData.slice(
        (this.current - 1) * this.pageSize,
        this.current * this.pageSize
      );
    },
    auth() {
      return this.$store.getters.getAuth;
    },
    actionUrl() {
      const base = `${BASE_URL}hnjxjy-core`;
      const url = this.again
        ? `${base}/eduOnlineQa/updateExemptionSubmitUrl`
        : `${base}/eduOnlineQa/exemptionSubmit`;
      return url;
    },
    actionData() {
      if (this.again) {
        return { id: this.againId };
      }
      return { courseId: this.form.selectCourse };
    },
  },
  mounted() {
    this.init();
    this.selectOptions();
  },
  methods: {
    init() {
      const params = {
        page: this.current,
        pageSize: this.pageSize,
      };
      this.loading = true;
      QueryExemptionSubmit(params)
        .then((res) => {
          this.tableData = res.data.list;
          this.total = res.data.total;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    selectOptions() {
      QueryExemptionCourse().then((res) => {
        this.selectCourseOptions = res.data;
      });
    },
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.init();
      }
    },

    selectionChange(val) {
      if (val) {
        this.selectData = val;
        this.$emit("selectData", this.selectData);
      }
    },
    onChange(file, fileList) {
      this.form.file = fileList;
    },
    handleRemove(file, fileList) {
      this.form.file = fileList;
    },
    OpenFile(url) {
      window.open(url);
    },
    againUpload(id) {
      if (id) {
        this.form.file = [];
        this.dialog = true;
        this.again = true;
        this.againId = id;
      }
    },
    confirm(refName) {
      // 重新上传
      if (this.again) {
        this.$refs.upload.submit();
        return;
      }
      this.$refs[refName].validate((valid) => {
        if (!valid) return false;

        this.$refs.upload.submit();
      });
    },
    onSuccess(res) {
      if (res.code === 2000) {
        this.dialog = false;
        this.$message.success(this.again ? "更新成功" : "增加成功");
        this.init();
      }
      this.form.file = [];
    },
  },
};
</script>

<style scoped lang="less">
.Exemptions {
}
</style>