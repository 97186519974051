<template>
  <div class="WriteInfo">
    <el-form :model="form" inline size="medium">
      <el-form-item label="课程名称/编号">
        <el-input v-model="form.course" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="query">查询</el-button>
        <el-button type="primary" @click="importExcel">导出考试信息</el-button>
      </el-form-item>
    </el-form>

    <el-table
      :data="datalist"
      :max-height="500"
      :height="500"
      ref="writeTableRef"
      stripe
    >
      <el-table-column label="序号" :width="60" align="center">
        <template slot-scope="scope">
          <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, index) in columns"
        :prop="item.prop"
        :key="index"
        :label="item.label"
        align="center"
        :min-width="item.width"
        :show-overflow-tooltip="item.tooltip"
      ></el-table-column>
    </el-table>
    <el-pagination
      class="pagination"
      background
      @current-change="handleCurrentChange"
      :current-page="current"
      :page-size="pageSize"
      layout="total, prev, pager, next, jumper"
      :total="tableData.length"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "write-info",
  data() {
    return {
      form: { course: null },
      columns: [
        { prop: "courseName", label: "课程名称", width: 160, tooltip: true },
        { prop: "way", label: "考试形式" },
        { prop: "type", label: "考试类型" },
        { prop: "batch", label: "考试批次", width: 120, tooltip: true },
        { prop: "field", label: "场次" },
        { prop: "create_at", label: "考试开始时间", width: 120, tooltip: true },
        { prop: "end_at", label: "考试结束时间", width: 120, tooltip: true },
        { prop: "room", label: "考场", width: 120, tooltip: true },
        { prop: "address", label: "地点", width: 120, tooltip: true },
        { prop: "place", label: "座位号" },
        { prop: "state", label: "状态", width: 120, tooltip: true },
      ],
      tableData: [],
      current: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: {
    datalist() {
      return this.tableData.slice(
        (this.current - 1) * this.pageSize,
        this.current * this.pageSize
      );
    },
  },
  methods: {
    query() {},
    importExcel() {},

    handleCurrentChange(page) {
      if (page) this.current = page;
    },
  },
};
</script>

<style scoped lang="less">
.WriteInfo {
}
</style>